import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { paths } from './@shared/constants/paths.costants';
import { AmountGuard } from './@core/guards/amount.guard';

const pathAmount = paths.find(path => path.id === 'amount')!.lazyPath;
const pathEmail = paths.find(path => path.id === 'email')!.lazyPath;
const pathError = paths.find(path => path.id === 'error')!.lazyPath;
const pathSuccess = paths.find(path => path.id === 'success')!.lazyPath;


const routes: Routes = [

  { path: pathAmount, loadChildren: () => import('./@feature/components/amount-entry/amount-entry.module').then(m => m.AmountEntryModule) },
  { path: pathEmail, loadChildren: () => import('./@feature/components/email-entry/email-entry.module').then(m => m.EmailEntryModule), canActivate: [AmountGuard] },
  { path: pathSuccess, loadChildren: () => import('./@feature/components/transaction-status/transaction-status.module').then(m => m.TransactionStatusModule) },
  { path: pathError, loadChildren: () => import('./@feature/components/transaction-error/transaction-error.module').then(m => m.TransactionErrorModule) },
  { path: '**', redirectTo: 'amount-entry' },
  { path: '', redirectTo: 'amount-entry', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
