import { inject, Injectable } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AmountService } from "../services/amount.service";
import { map, Observable } from "rxjs";
import { paths } from "../../@shared/constants/paths.costants";
import { REGEX_DOTS } from "../../@shared/constants/rejex.costants";

export const AmountGuard: CanActivateFn = () => {
    const amountService = inject(AmountService);
    const router = inject(Router);

    return amountService.currentAmount$.pipe(
        map(amount => {

            amount = amount.replace(REGEX_DOTS, '')

            if (Number(amount.replace(',', '.')) > 0) {
                return true;
            } else {
                const amountPath = paths.find(path_ => path_.id === "amount")!.absolutePath;
                router.navigate([amountPath]); // Redireziona se l'importo è 0 o inferiore
                return false;
            }
        })
    );
};
